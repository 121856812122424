<template>
  <div class="zone">

    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'装机信息'" />
    <TJDetailMenu :dataList="dataListB" />

    <TJDetailTitle :titleData="'装机单'" />

    <span v-if="detailData.pdf_file">PDF加载可能较慢,请等待...</span>
    <span v-else>暂无装机单</span>

    <div class="printBox">
       <embed 
        id="printMe"
        :src="detailData.pdf_file"
        type="application/pdf"
        width="100%"
        height="800px" />
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import { getBase64 } from '@/utils/common'
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'InstalltaskDetail',
  components: { TJDetailTitle, TJDetailMenu },

  data() {
    return {
      detailId: '',
      dataList: [
        {
          name: '医院',
          value: '',
          width: '33%',
        },
        {
          name: '机号',
          value: '',
          width: '33%',
        },
        {
          name: '平台',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '仪器位置',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '经销商',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
      ],
      dataListB: [
        {
          name: '装机人员',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '申请时间',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '完成时间',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
      ],
      detailData: {},
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitinstalltaskDetail()
  },
  methods: {
    gitinstalltaskDetail() {
      apiDetail.installtaskDetail({ task_id: this.detailId }).then((res) => {
        this.detailData = res.data
        this.dataList[0].value = this.detailData.hospital_name
        this.dataList[1].value = this.detailData.instrument_id
        this.dataList[2].value = this.detailData.platform_unique
        this.dataList[3].value = this.detailData.hos_pos_name
        this.dataList[4].value = this.detailData.customer_name

        
        this.dataListB[0].value = this.detailData.implement_engineer_name
        this.dataListB[1].value = this.detailData.apply_date
        this.dataListB[2].value = this.detailData.implement_date

      })
    },
    download() {
      let _this = this
      if(_this.detailData.pdf_file == '' || _this.detailData.pdf_file == null){
        this.$message.warning('下载路径为空！')
        return
      }
      getBase64(_this.detailData.pdf_file, (url) => {
        var imageData = url
        var doc = new jsPDF('landscape', 'pt', [205, 115])
        doc.addImage(imageData, 'PNG', 0, 0, 205, 115)
        doc.save('a4.pdf')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.printBox{
  margin-top: px(20);
}
</style>
